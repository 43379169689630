import React, { ForwardedRef, forwardRef } from "react";
import { Box as ChakraBox, BoxProps } from "@chakra-ui/react";

const Box = forwardRef(({ ...props }: BoxProps, ref: ForwardedRef<any>) => {
  return (
    <ChakraBox ref={ref} {...props}>
      {props.children}
    </ChakraBox>
  );
});

export default Box;

import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import { SplashScreen } from "../SplashScreen";
import FadeTransition from "../Transition/Fade";

interface BackgroundBoxProps extends BoxProps {
  background: string;
}

const BackgroundBox = ({ background, ...rest }: BackgroundBoxProps) => {
  if (!background) {
    return <SplashScreen text="Preparing..." pos="fixed" />;
  }

  return (
    <FadeTransition>
      <Box
        target="_blank"
        pos="fixed"
        w="100svw"
        h="100svh"
        bgPos="center"
        bgAttachment="fixed"
        bgImg={background}
        bgSize="cover"
        top="0"
        left="0"
        zIndex="0"
        {...rest}
      />
    </FadeTransition>
  );
};

export default BackgroundBox;

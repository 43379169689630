import React from "react";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";
import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";

const Link = ({ ...rest }: GatsbyLinkProps<any> | LinkProps) => {
  return (
    <ChakraLink as={"to" in rest ? GatsbyLink : undefined} {...rest}>
      {rest.children}
    </ChakraLink>
  );
};

export default Link;

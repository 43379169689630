import React from "react";
import { SplashScreen } from "../components/common/SplashScreen";
import { User, useGetUserQuery } from "../store/api/user";
import { AuthContext } from "./AuthProvider";

export const UserContext = React.createContext<User | undefined>(undefined);

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { user: authUser, isLoggedIn } = React.useContext(AuthContext);

  const user = useGetUserQuery(
    {
      userId: authUser!.getUsername() ?? undefined,
    },
    {
      skip: !isLoggedIn(),
      refetchOnMountOrArgChange: true,
    },
  );

  const restrictions = React.useMemo(() => {
    return {
      downloadRestrictions: {
        ...user.data?.service?.downloadRestrictions,
        ...user.data?.downloadRestrictions,
      },
      uploadRestrictions: {
        ...user.data?.service?.uploadRestrictions,
        ...user.data?.uploadRestrictions,
      },
      options: {
        ...user.data?.service?.options,
        ...user.data?.options,
      },
    };
  }, [user.data]);

  if (isLoggedIn() && !user.data) {
    return <SplashScreen text="Getting your user data..." />;
  }

  return (
    <UserContext.Provider
      // @ts-ignore
      value={{
        ...user.data,
        ...restrictions,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;

import React from "react";
import Box from "../common/Box/Box";
import Heading from "../common/Heading/Heading";
import Text from "../common/Text/Text";
import Link from "../common/Link/Link";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";
import Button from "../common/Button";
import Modal from "../common/Modal/Modal";
import { useDisclosure } from "@chakra-ui/react";
import Accordion from "../common/Accordion/Accordion";
import Switch from "../common/Input/Switch";

const AppTerms = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [, setCookie] = useCookies([
    "APP_CONSENT",
    "APP_ANALYTICS",
    "APP_FUNCTIONAL",
  ]);

  const [checked, setChecked] = React.useState({
    functional: true,
    analytics: true,
  });

  return (
    <Box
      display="flex"
      flexDir="column"
      bg="white"
      p="4"
      m="4"
      gap="4"
      color="black"
      rounded="lg"
      textAlign="center"
    >
      <Box>
        <Heading size="md">Welcome to the new MailBigFIle</Heading>
      </Box>

      <Box display="flex" flexDir="column" gap="4">
        <Text>
          As part of our commitment to provide the best possible experience to
          our customers, we are upgrading and amalgamating our services.
        </Text>

        <Text>
          Business and Pro Account users will need to continue to use the same
          login method as before. Free users can however use our new service.
        </Text>

        <Text>
          In order to use the service, please accept our{" "}
          <Link
            href="https://www.mailbigfile.com/terms"
            textDecor="underline"
            target="_blank"
          >
            Terms and Conditions
          </Link>
          .
        </Text>

        <Text>
          We also use cookies to secure and improve the service we provide.
        </Text>
      </Box>

      <Box display="flex" flexDir="column" gap="4">
        <Button onClick={onOpen}>Manage Cookies</Button>

        <Button
          onClick={() => {
            setCookie("APP_CONSENT", true, {
              path: "/",
              expires: dayjs().add(1, "year").toDate(),
            });

            if (checked.functional) {
              setCookie("APP_FUNCTIONAL", true, {
                path: "/",
                expires: dayjs().add(1, "year").toDate(),
              });
            }

            if (checked.analytics) {
              setCookie("APP_ANALYTICS", true, {
                path: "/",
                expires: dayjs().add(1, "year").toDate(),
              });
            }
          }}
        >
          I Accept
        </Button>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Overlay />

        <Modal.Content m="4">
          <Modal.Header>Manage Cookies</Modal.Header>

          <Modal.CloseButton />

          <Modal.Body display="flex" flexDir="column" gap="4">
            <Accordion allowMultiple defaultIndex={[0, 1, 2]}>
              <Text textAlign="center" mb="4">
                You can read more about our Cookies in our{" "}
                <Link
                  href="https://www.mailbigfile.com/policies/cookie"
                  textDecor="underline"
                  target="_blank"
                >
                  Cookie Policy
                </Link>
                .
              </Text>

              <Accordion.Item>
                <Accordion.Button
                  onClick={(e) => {
                    if (
                      Array.from(
                        (e.target as HTMLElement).classList,
                      )[0].includes("switch")
                    ) {
                      e.preventDefault();
                    }
                  }}
                >
                  <Text as="span" flex="1" textAlign="left" fontWeight="bold">
                    Essential
                  </Text>

                  <Switch isReadOnly isDisabled defaultChecked />
                </Accordion.Button>

                <Accordion.Panel display="flex" flexDir="column" gap="4">
                  <Text>
                    These cookies are necessary for the website to function and
                    cannot be switched off in our systems. They are usually only
                    set in response to actions made by you which amount to a
                    request for services, such as setting your privacy
                    preferences, logging in or filling in forms. You can set
                    your browser to block or alert you about these cookies, but
                    some parts of the site may not work properly in that case.
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Button
                  onClick={(e) => {
                    if (
                      Array.from(
                        (e.target as HTMLElement).classList,
                      )[0].includes("switch")
                    ) {
                      e.preventDefault();

                      setChecked({
                        ...checked,
                        functional: !checked.functional,
                      });
                    }
                  }}
                >
                  <Text as="span" flex="1" textAlign="left">
                    Performance and Functionality
                  </Text>

                  <Switch defaultChecked isChecked={checked.functional} />
                </Accordion.Button>

                <Accordion.Panel display="flex" flexDir="column" gap="4">
                  <Text>
                    These cookies enable the website to provide enhanced
                    functionality and display customized videos and images. They
                    may be set by us or by third party providers whose services
                    we have added to our pages. If you do not allow these
                    cookies, then some or all of these services may not function
                    properly.
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Button
                  onClick={(e) => {
                    if (
                      Array.from(
                        (e.target as HTMLElement).classList,
                      )[0].includes("switch")
                    ) {
                      e.preventDefault();

                      setChecked({
                        ...checked,
                        analytics: !checked.analytics,
                      });
                    }
                  }}
                >
                  <Text as="span" flex="1" textAlign="left">
                    Analytics and Customisation
                  </Text>

                  <Switch defaultChecked isChecked={checked.analytics} />
                </Accordion.Button>

                <Accordion.Panel display="flex" flexDir="column" gap="4">
                  <Text>
                    These cookies allow us to count visits and traffic sources
                    so we can measure and improve the performance of our site.
                    They help us understand which pages are the most and least
                    popular and learn how visitors move around the site. If you
                    do not allow these cookies we will not know when you have
                    visited our site and will not be able to monitor its
                    performance.
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Modal.Body>

          <Modal.Footer display="flex" gap="4">
            <Button
              onClick={() => {
                setChecked({
                  analytics: true,
                  functional: true,
                });

                onClose();
              }}
            >
              Accept All
            </Button>
            <Button onClick={onClose}>Close</Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Box>
  );
};

export default AppTerms;

// React
import React from 'react'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'

// Providers
import FontAwesomeProvider from './src/providers/FontAwesomeProvider'
import { Provider } from 'react-redux'
import store from './src/store/index'
import Logger, { LogLevel } from './src/singletons/Logger'
import AppLayout from './src/layout/app'

if (process.env.NODE_ENV !== 'production') {
  Logger.logLevel = LogLevel.Debug
} else {
  Logger.logLevel = LogLevel.Error
}

// Temporary fix for dynamic pages created in gatsby-node.
// The issue is caused by the Chakra Plugin usage with the createPages API in Gatsby Node.
export function replaceHydrateFunction() {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

export const wrapRootElement = ({ element }) => (
  <RecoilRoot>
    <Provider store={store}>
      <FontAwesomeProvider>
        { element }
      </FontAwesomeProvider>
    </Provider>
  </RecoilRoot>
)

export const wrapPageElement = ({ element }) => {
  return (
    <AppLayout>
      { element }
    </AppLayout>
  )
}
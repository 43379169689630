import { Center, Flex, FlexProps } from "@chakra-ui/react";
import { AuthContext } from "../../providers/AuthProvider";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useCookies } from "react-cookie";
import Icon from "../common/Icon/Icon";
import Link from "../common/Link/Link";
import GeneralMenu from "./Menu/General";
import UserMenu from "./Menu/User";

const Navigation = ({ ...rest }: FlexProps) => {
  const { isLoggedIn } = React.useContext(AuthContext);

  const [cookies] = useCookies(["APP_CONSENT"]);

  const show = React.useMemo(() => {
    return {
      menu: !!cookies.APP_CONSENT,
      logo: true,
      user: !!cookies.APP_CONSENT,
    };
  }, [cookies.APP_CONSENT]);

  return (
    <Flex {...rest}>
      {show.menu && (
        <Center>
          <GeneralMenu />
        </Center>
      )}

      {show.logo && (
        <Center flex="1">
          <Link to="/">
            {!show.menu && !show.user && (
              <StaticImage src="../../images/logo.png" alt="MailBigFile Logo" />
            )}

            {show.menu && show.user && (
              <StaticImage
                src="../../images/logoSmall.png"
                alt="MailBigFile Small Logo"
                height={32}
              />
            )}
          </Link>
        </Center>
      )}

      {show.user && (
        <Center>
          <Link href="https://max.mailbigfile.com" target="_blank">
            <Icon icon='user' color='white' size='xl' />
          </Link>

          {/* {(() => {
            if (isLoggedIn()) {
              return <UserMenu />;
            }

            return (
              <Link to="/login">
                <Icon icon="user" color="white" size="xl" />
              </Link>
            );
          })()} */}
        </Center>
      )}
    </Flex>
  );
};

export default Navigation;

import { Sha256 } from "@aws-crypto/sha256-js";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import {
  FromCognitoIdentityPoolParameters,
  fromCognitoIdentityPool,
} from "@aws-sdk/credential-provider-cognito-identity";
import { HttpRequest } from "@aws-sdk/protocol-http";
import { SerializedError } from "@reduxjs/toolkit";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {
  FetchArgs,
  FetchBaseQueryError,
  createApi,
} from "@reduxjs/toolkit/query/react";
import { SignatureV4 } from "@smithy/signature-v4";
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import queryString from "query-string";
import Logger from "../singletons/Logger";

export interface IModel {
  time: { created: string; updated: string };
}

export interface Timestamps {
  created: string;
  updated: string;
}

export interface QueryStringParameters {
  fields?: Array<string>;
  filter?: string | Array<string> | Record<string, string>;
  search?: string;
  order?: string;
  order_direction?: "ASC" | "DESC";
  limit?: number;
  offset?: number;
  with?: string | Array<string>;
  recaptcha?: {
    token: string;
    action: string;
  };
  [key: string]: any;
}

const tags = [
  "Transfer",
  "User",
  "Verification",
  "File",
  "Comment",
  "Client",
  "Price",
  "Subscription",
] as const;

export type TagTypes = (typeof tags)[number];

export const getIAMHeaders = async (input: {
  url: string;
  method: string;
  body?: any;
}) => {
  if (!process.env.GATSBY_API_GATEWAY) {
    throw new Error("No API Gateway configured");
  }

  if (!process.env.GATSBY_COGNITO_IDENTITY_POOL_ID) {
    throw new Error("No Identity Pool ID configured");
  }

  const host = process.env.GATSBY_API_GATEWAY.replace(/https?\:\/\//, "");

  const params: FromCognitoIdentityPoolParameters = {
    identityPoolId: process.env.GATSBY_COGNITO_IDENTITY_POOL_ID,
    client: new CognitoIdentityClient({ region: "eu-west-1" }),
  };

  const credentials = await fromCognitoIdentityPool(params)();

  const signer = new SignatureV4({
    credentials,
    sha256: Sha256,
    region: "eu-west-1",
    service: "execute-api",
  });

  const parsed = queryString.parseUrl(input.url);

  const request = new HttpRequest({
    method: input.method,
    body: input.body ? JSON.stringify(input.body) : null,
    hostname: host,
    path: parsed.url.replace(process.env.GATSBY_API_GATEWAY!, ""),
    // @ts-ignore. They are basically the same. Please shut up.
    query: parsed.query,
    headers: {
      "Content-Type": "application/json",
      host,
    },
  });

  return (await signer.sign(request)).headers;
};

export const getJWTHeaders = (session: CognitoUserSession) => {
  return {
    Authorization: session.getIdToken().getJwtToken(),
    "Content-Type": "application/json",
  };
};

export const getErrorMessage = (
  error: FetchBaseQueryError | SerializedError | string | undefined,
) => {
  if (error === undefined) {
    return "Sorry, something went wrong with your query";
  }

  if (typeof error === "string") {
    return error;
  }

  if ("error" in error) {
    return error.error;
  }

  if ("data" in error && typeof error.data === "object" && error.data) {
    if ("message" in error.data && typeof error.data.message === "string") {
      return error.data.message;
    }

    if ("error" in error.data && typeof error.data.error === "string") {
      return error.data.error;
    }
  }

  if ("status" in error && error.status === 403) {
    return "You do not have permission to the requested resource";
  }

  if ("status" in error && error.status === 404) {
    return "The requested resource could not be found";
  }

  return "Sorry, something went wrong contacting our server";
};

const serialize = function (
  obj?: Record<string, string>,
  prefix?: string,
): string {
  if (!obj) return "";

  var str = [],
    p;

  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];

      if (v) {
        if (typeof v === 'object') {
          str.push(serialize(v, k))
        } else {
          str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v),)
        }
      }

      // str.push(
      //   v !== null && typeof v === "object"
      //     ? serialize(v, k)
      //     : encodeURIComponent(k) + "=" + encodeURIComponent(v),
      // );
    }
  }

  return str.join("&");
};

export const constructRequest = async ({
  session,
  args,
}: {
  session?: CognitoUserSession;
  args: FetchArgs;
}) => {
  const url = new URL(
    `${!session ? "public/" : ""}${args.url}`,
    process.env.GATSBY_API_GATEWAY,
  );
  url.search = serialize(args.params);

  let headers: HeadersInit;
  if (!session) {
    headers = await getIAMHeaders({
      url: url.href,
      method: args.method ?? "GET",
      body: args.body,
    });
  } else {
    headers = getJWTHeaders(session);
  }

  return new Request(url, {
    ...args,
    body: args.body ? JSON.stringify(args.body) : undefined,
    headers,
  });
};

const api = createApi({
  reducerPath: "api",
  tagTypes: tags,
  baseQuery: async (args: string | FetchArgs, api, extraOptions) => {
    Logger.debug("Request received", args, api, extraOptions);

    if (typeof args === "string") {
      args = {
        url: args,
      };
    }

    const query: QueryReturnValue<
      any | undefined,
      FetchBaseQueryError | SerializedError | undefined,
      { request?: Request; response?: Response }
    > = {
      data: undefined,
      error: undefined,
      meta: {
        request: undefined,
        response: undefined,
      },
    };

    let session: CognitoUserSession | null = null;

    for (const cookie of (document && document.cookie.split(";")) ?? []) {
      if (cookie.includes("APP_SESSION")) {
        const Username = cookie.split("=")[1];

        const user = new CognitoUser({
          Username,
          Pool: new CognitoUserPool({
            ClientId: `${process.env.GATSBY_COGNITO_CLIENT_ID}`,
            UserPoolId: `${process.env.GATSBY_COGNITO_USER_POOL_ID}`,
          }),
        });

        // This just helps ensure the below works correctly. Don't ask.
        user.getSession(() => {
          return;
        });

        // Cannot check for not-null on a function unless you declare a variable on its return value
        // This is because Typescript cannot be sure of the return value of the function unless it gets stored
        session = user.getSignInUserSession();
      }
    }

    try {
      // const url = new URL(
      //   `${!session ? "public/" : ""}${args.url}`,
      //   process.env.GATSBY_API_GATEWAY,
      // );
      // url.search = serialize(args.params);

      // let headers: HeadersInit;
      // if (!session) {
      //   headers = await getIAMHeaders({
      //     url: url.href,
      //     method: args.method ?? "GET",
      //     body: args.body,
      //   });
      // } else {
      //   headers = getJWTHeaders(session);
      // }

      // const request = new Request(url, {
      //   body: args.body ? JSON.stringify(args.body) : undefined,
      //   headers,
      //   method: args.method,
      // });

      const request = await constructRequest({
        session: session || undefined,
        args,
      });

      query.meta!.request = request.clone();

      Logger.debug("Firing request", request);

      const response = await fetch(request);

      Logger.debug("Request response", response);

      try {
        const data = await response.json();

        if (response.status > 299) {
          query.error = {
            error: data.message ?? data.error ?? response.statusText,
            status: "FETCH_ERROR",
            data,
          };
        } else {
          query.data = data;
        }
      } catch (e) {
        if (e instanceof Error) {
          Logger.debug("JSON unwrap error", e.message);
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.error(e.message);

        query.error = {
          error: e.message,
          status: "FETCH_ERROR",
        };
      }
    }

    Logger.debug("Returning query", query);

    return query;
  },
  endpoints: () => ({}),
});

export default api;

// https://chakra-ui.com/docs/styled-system/customize-theme

// `@chakra-ui/theme` is a part of the base install with `@chakra-ui/react`
import { extendTheme } from '@chakra-ui/react'

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
  zIndex: 2
}

const theme = extendTheme({
  colors: {
    brand: {
      50: '#F5B8DF',
      100: '#F2A6D7',
      200: '#F094D0',
      300: '#ED82C8',
      400: '#EB70C0',
      500: '#E755B5',
      600: '#E33BA8',
      700: '#D61F96',
      800: '#B3197D',
      900: '#8F1464',
    }
  },
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles
              }
            },
            "label:has(~ textarea:not(:placeholder-shown)), textarea:not(:placeholder-shown) ~ label": {
              ...activeLabelStyles
            },
            "label:has(+ input:not(:placeholder-shown)), input:not(:placeholder-shown) + label": {
              ...activeLabelStyles
            },
            "label:has(+ .chakra-select__wrapper), chakra-select__wrapper + label": {
              ...activeLabelStyles
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 1,
              position: "absolute",
              backgroundColor: "transparent",
              pointerEvents: "none",
              backdropFilter: 'blur(3rem)',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top"
            }
          }
        }
      }
    }
  }
})

export const baseTheme = {}
export default theme
import { BoxProps } from "@chakra-ui/react";
import React from "react";
import { useCookies } from "react-cookie";
import Box from "../common/Box/Box";
import Navigation from "./Navigation";
import Divider from "../common/Divider/Divider";
import AppTerms from "./Terms";
import { Script } from "gatsby";
import { UppyProvider } from "../../providers/UppyProvider";
import UploadDisclaimer from "../Upload/Disclaimer";

const AppBox = ({ ...rest }: BoxProps) => {
  const [cookies] = useCookies(["APP_CONSENT", "APP_ANALYTICS"]);

  const dimensions = React.useMemo(() => {
    const isMobile = typeof window !== "undefined" && window.innerWidth < 450;

    return {
      minW: "300px",
      minH: "450px",
      w: isMobile ? "fill-available" : "max-content",
      h: isMobile ? "fill-available" : "auto",
      maxW: "calc(100% - 2rem)",
      maxH: "calc(100% - 2rem)",
    };
  }, []);

  return (
    <UppyProvider>
      <Box
        id="uppy-container"
        display="flex"
        flexDir="column"
        bgGradient="linear(to-tr, #625fa2, #e358b4)"
        resize="both"
        overflow="auto"
        m="4"
        position="absolute"
        borderRadius="lg"
        borderColor="white"
        borderWidth="thin"
        zIndex="1"
        color="white"
        {...dimensions}
        {...rest}
      >
        <Navigation p="4" />

        <Box>
          <Divider borderBottomWidth="2px" pos="absolute" />
        </Box>

        {(() => {
          if (!cookies.APP_CONSENT) {
            return <AppTerms />;
          }

          return (
            <Box display="flex" flex="1" overflow="auto" p="0">
              {rest.children}

              <Script src="https://www.google.com/recaptcha/enterprise.js?render=6LccJpsoAAAAAPprRFUQifRFiskyR11jSM-B2nZy" />

              {cookies.APP_ANALYTICS &&
                process.env.GATSBY_GOOGLE_ANALYTICS_COLLECT && (
                  <>
                    <Script
                      async
                      src="https://www.googletagmanager.com/gtag/js?id=G-0NJGDNTJ6Q"
                    />

                    <Script>
                      {`window.dataLayer = window.dataLayer || [];
                          function gtag(){dataLayer.push(arguments);}
                          gtag('js', new Date());

                          gtag('config', 'G-0NJGDNTJ6Q');`}
                    </Script>
                  </>
                )}
            </Box>
          );
        })()}

        <Divider />
        <UploadDisclaimer />
      </Box>
    </UppyProvider>
  );
};

export default AppBox;

import api, { QueryStringParameters } from "../api";
import {
  AccountOptions,
  DownloadRestrictions,
  Service,
  UploadRestrictions,
} from "./service";

export type NotificationPreferences = {
  browser?: {
    onTransferComplete?: boolean;
  };
  server?: {
    onRecipientDownload?: boolean;
  };
};

export interface User {
  id: string;
  name?: string;
  email: string;
  options?: AccountOptions;
  uploadRestrictions?: UploadRestrictions;
  downloadRestrictions?: DownloadRestrictions;
  service?: Service;
  notificationPreferences?: NotificationPreferences;
}

const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query({
      query: ({
        userId,
        params,
      }: {
        userId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `user/${userId}`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: User) {
        return baseQueryReturnValue;
      },
      providesTags: (result, err, { userId }) => [{ type: "User", id: userId }],
    }),
    updateUser: build.mutation({
      query: ({
        userId,
        body,
      }: {
        userId: string;
        body: Partial<Omit<User, "id" | "email">>;
      }) => ({
        url: `user/${userId}`,
        method: "PATCH",
        body,
      }),
      transformResponse(baseQueryReturnValue: User) {
        return baseQueryReturnValue;
      },
      invalidatesTags: (result, error, { userId }) => [
        { type: "User", id: userId },
      ],
    }),
  }),
});

export const { useGetUserQuery, useUpdateUserMutation } = userApi;

export default userApi;

import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import Icon from "./Icon/Icon";

const Loading = ({
  text = "Loading...",
  spinner = true,
  ...props
}: { text?: string; spinner?: boolean } & BoxProps) => {
  return (
    <Box
      display="flex"
      flexDir="column"
      gap="2"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      {spinner && <Icon className="fa-spin" color="white" icon="spinner" />}

      <span>{text}</span>
    </Box>
  );
};

export default Loading;

import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserPoolData,
  UserData,
} from "amazon-cognito-identity-js";
import React from "react";
import { useCookies } from "react-cookie";
import api from "../store/api";
import { useAppDispatch } from "../store";
import { SplashScreen } from "../components/common/SplashScreen";
import { add } from "date-fns";
import { useToast } from "@chakra-ui/react";
import Logger from "../singletons/Logger";
import { useCreateLoginMutation } from "../store/api/login";

export class AuthUser extends CognitoUser {
  private listeners = {} as any;

  public static async onEntryAuthenticate(
    pool: ICognitoUserPoolData,
  ): Promise<AuthUser> {
    const cookies = document.cookie.split(";");

    for (const cookie of cookies) {
      if (cookie.includes("APP_SESSION")) {
        const Username = cookie.split("=")[1];

        const user = new AuthUser({
          Username,
          Pool: new CognitoUserPool(pool),
        });

        return await new Promise<AuthUser>((resolve) => {
          user.getSession((err: Error | null, session: CognitoUserSession) => {
            if (err) {
              return resolve(
                new AuthUser({
                  Username: "",
                  Pool: new CognitoUserPool(pool),
                }),
              );
            }

            user.refreshSession(session.getRefreshToken(), (err, result) => {
              if (err) {
                return resolve(
                  new AuthUser({
                    Username: "",
                    Pool: new CognitoUserPool(pool),
                  }),
                );
              }

              resolve(user);
            });
          });
        });
      }
    }

    return new AuthUser({
      Username: "",
      Pool: new CognitoUserPool(pool),
    });
  }

  public listen(to: string, listener: Function) {
    if (!(to in this.listeners)) {
      this.listeners[to] = [] as Array<Function>;
    }

    this.listeners[to].push(listener);
  }

  public ignore(to: string, listener: Function) {
    if (!(to in this.listeners)) {
      console.warn(`${to} does not exist as a listener group`);

      return;
    }

    this.listeners[to].splice(
      this.listeners[to].findIndex((l: Function) => l === listener),
      1,
    );
  }
}

export interface IAuthContext {
  user?: AuthUser;
  setUser: (user: AuthUser) => void;
  userData?: UserData;
  setUserData: (data?: UserData) => void;
  isLoggedIn: () => boolean;
  login: ({
    remember,
    userId,
  }: {
    remember: boolean;
    userId: string;
  }) => Promise<void>;
  logout: () => void;
}

export const AuthContext = React.createContext<IAuthContext>({
  setUser(user) {
    return;
  },
  setUserData(data) {
    return;
  },
  isLoggedIn() {
    return false;
  },
  async login({ remember, userId }) {
    return;
  },
  logout() {
    return;
  },
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();

  const [user, setUser] = React.useState(
    new AuthUser({
      Username: "",
      Pool: new CognitoUserPool({
        ClientId: process.env.GATSBY_COGNITO_CLIENT_ID!,
        UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID!,
      }),
    }),
  );
  const [userData, setUserData] = React.useState<UserData | undefined>(
    undefined,
  );

  const [cookies, setCookie, removeCookie] = useCookies(["APP_SESSION"]);
  const [createLogin, createLoginState] = useCreateLoginMutation();

  const isLoggedIn = () => {
    return !!(user && user.getSignInUserSession());
  };

  const login = async ({
    remember,
    userId,
  }: {
    remember: boolean;
    userId: string;
  }) => {
    setCookie("APP_SESSION", userId, {
      path: "/",
      expires: remember ? add(new Date(), { days: 7 }) : undefined,
    });

    await createLogin({});

    dispatch(api.util.resetApiState());
  };

  const logout = () => {
    Logger.debug("User signed out. Resetting application state.");

    removeCookie("APP_SESSION", { path: "/" });

    user.signOut();

    dispatch(api.util.resetApiState());
  };

  React.useEffect(() => {
    Logger.debug("APP_SESSION cookie changed", cookies.APP_SESSION);

    if (cookies.APP_SESSION) {
      (async () => {
        const user = await AuthUser.onEntryAuthenticate({
          ClientId: process.env.GATSBY_COGNITO_CLIENT_ID!,
          UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID!,
        });

        if (user.getSignInUserSession()) {
          Logger.debug("User is signed in");

          setUserData(
            await new Promise<UserData | undefined>((resolve, reject) => {
              Logger.debug("Setting user data");

              user.getUserData((err, result) => {
                if (err) {
                  reject(err);
                }

                resolve(result);
              });
            }),
          );

          const onWindowFocus = () => {
            if (user.getSignInUserSession()) {
              user?.getSession(
                (err: Error | null, result: CognitoUserSession) => {
                  if (err) {
                    logout();
                  }

                  if (result && !result.isValid()) {
                    // FIXME: Think this is not working correctly.
                    user?.refreshSession(result.getRefreshToken(), (err) => {
                      if (err) {
                        logout();
                      }
                    });
                  }
                },
              );
            }
          };

          window.addEventListener("focus", onWindowFocus);

          setUser(user);

          return function cleanup() {
            window.removeEventListener("focus", onWindowFocus);
          };
        } else {
          Logger.debug("User not signed in. Removing cookie");

          removeCookie("APP_SESSION", { path: "/" });
        }
      })();
    }
  }, [cookies.APP_SESSION]);

  // FIXME: Handle error on loading the database user.
  if (
    (cookies.APP_SESSION && !user.getSignInUserSession() && !userData) ||
    createLoginState.isLoading
  ) {
    return <SplashScreen text="Authenticating..." />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        userData,
        setUserData,
        login,
        logout,
        isLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import api, { QueryStringParameters, TagTypes, Timestamps } from "../api";
import { Comment } from "./comment";
import { File } from "./file";
import { User } from "./user";

export interface Transfer {
  id: string;
  userId: string;
  title: string;
  from: string;
  recipients?: Array<TransferRecipient>;
  toEntry: string;
  linkOnly: boolean;
  message: string;
  password: string;
  expiration: string;
  downloadRestrictions?: {
    maxDownloads?: number;
  };
  files?: Array<File>;
  comments?: Array<Comment>;
  downloads?: Array<TransferDownload>;
  user?: User;
  time: Timestamps;
}

export interface UploadRestrictions {
  maxTransferSize: string;
  maxFileSize: string;
  maxNumberOfFiles: number;
  maxRecipients: number;
  maxFileAvailability: number;
}

export interface TransformedUploadRestrictions {
  maxTransferSize: number;
  maxFileSize: number;
  maxNumberOfFiles: number;
  maxRecipients: number;
  maxFileAvailability: number;
}

export type TransferRecipient = {
  email: string;
  userId: string;
  transferId: string;
  transfer?: Transfer;
  user: User;
} & Timestamps;

export type TransferDownload = {
  id: string;
  transferId: string;
  userId: string;
  ip: string;
  userAgent: string;
  transfer?: Transfer;
  user?: User;
} & Timestamps;

export type TransferTotals = {
  size: number;
  files: number;
  downloads: number;
  comments: number;
};

const transferApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTransfer: build.query({
      query: ({
        transferId,
        params,
      }: {
        transferId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `transfer/${transferId}`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Transfer) {
        return baseQueryReturnValue;
      },
      providesTags: (result, err, { transferId }) => {
        if (!result) {
          return ["Transfer"];
        }

        return [{ type: "Transfer", id: transferId }];
      },
    }),
    getTransfersByUser: build.query<
      Transfer[],
      { userId: string; params?: QueryStringParameters }
    >({
      query: ({ userId, params }) => ({
        url: `user/${userId}/transfer`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { userId }) => {
        if (!result || result.length <= 0) {
          return ["Transfer", "User"];
        }

        return result
          .map<{ type: TagTypes; id: string }>((item) => {
            return { type: "Transfer", id: item.id };
          })
          .concat([{ type: "User", id: userId }]);
      },
    }),
    getTransferTotals: build.query<
      TransferTotals,
      { transferId: string; params?: QueryStringParameters }
    >({
      query: ({ transferId, params }) => ({
        url: `transfer/${transferId}/totals`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { transferId }) => {
        if (!result) {
          return ["Transfer"];
        }

        return [{ type: "Transfer", id: transferId }];
      },
    }),
    getTransferRecipients: build.query<
      TransferRecipient[],
      { transferId: string; params?: QueryStringParameters }
    >({
      query: ({ transferId, params }) => ({
        url: `transfer/${transferId}/recipients`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { transferId }) => {
        if (!result) {
          return ["Transfer"];
        }

        return [{ type: "Transfer", id: transferId }];
      },
    }),
    getReceivedTransfersByUser: build.query<
      Transfer[],
      { userId: string; params?: QueryStringParameters }
    >({
      query: ({ userId, params }) => ({
        url: `user/${userId}/transfer/received`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { userId }) => {
        if (!result || result.length <= 0) {
          return ["Transfer", "User"];
        }

        return result
          .map<{ type: TagTypes; id: string }>((item) => {
            return { type: "Transfer", id: item.id };
          })
          .concat([{ type: "User", id: userId }]);
      },
    }),
    getTransferTotalsByUser: build.query<
      { size: number; count: number },
      { userId: string; params?: QueryStringParameters }
    >({
      query: ({ userId, params }) => ({
        url: `user/${userId}/transfer/totals`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { userId }) => {
        return ["Transfer", "User"];
      },
    }),
    getTransferFiles: build.query({
      query: ({
        transferId,
        params,
      }: {
        transferId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `transfer/${transferId}/file`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<File>) {
        return baseQueryReturnValue;
      },
      providesTags: (result, err, { transferId }) => {
        if (!result || result.length <= 0) {
          return ["Transfer", "File"];
        }

        return result
          .map<{ type: TagTypes; id: string }>((item) => {
            return { type: "File", id: item.id };
          })
          .concat([{ type: "Transfer", id: transferId }]);
      },
    }),
    getDownloadLink: build.query({
      query: ({ transferId, params }: { transferId: string, params?: { recipient?: string | null } }) => ({
        url: `transfer/${transferId}/download`,
        method: "GET",
        params
      }),
      transformResponse(baseQueryReturnValue: { url: string }) {
        return baseQueryReturnValue;
      },
    }),
    validateTransfer: build.mutation({
      query: ({
        body,
        params,
      }: {
        body: {
          from: string;
          title: string;
          recipients: Array<string>;
          files: Array<{ name: string; size: number; type?: string }>;
          password?: string;
          message?: string;
          expiration?: string;
        };
        params?: QueryStringParameters;
      }) => ({
        url: `transfer/validate`,
        method: "POST",
        body,
        params,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Transfer"],
    }),
    validateTransferPassword: build.mutation<
      {},
      { transferId: string; body: { password: string } }
    >({
      query: ({ transferId, body }) => ({
        url: `transfer/${transferId}/validate/password`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Transfer"],
    }),
    createTransfer: build.mutation({
      query: ({
        body,
        params,
      }: {
        body: {
          from: string;
          title: string;
          recipients: Array<string>;
          files: Array<{ name: string; size: number; type?: string }>;
          password?: string;
          message?: string;
          expiration?: string;
        };
        params?: QueryStringParameters;
      }) => ({
        url: `transfer`,
        method: "POST",
        body,
        params,
      }),
      transformResponse(baseQueryReturnValue: Transfer) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Transfer"],
    }),
    updateTransfer: build.mutation({
      query: ({
        transferId,
        body,
      }: {
        transferId: string;
        body: {
          password?: string;
          message?: string;
          expiration?: string;
        };
      }) => ({
        url: `transfer/${transferId}`,
        method: "PATCH",
        body,
      }),
      transformResponse(baseQueryReturnValue: Transfer) {
        return baseQueryReturnValue;
      },
      invalidatesTags: (res, err, { transferId }) => [
        { type: "Transfer", id: transferId },
      ],
    }),
    deleteTransfer: build.mutation<Transfer, { transferId: string }>({
      query: ({ transferId }) => ({
        url: `transfer/${transferId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Transfer"],
    }),
  }),
});

export const {
  useCreateTransferMutation,
  useValidateTransferMutation,
  useGetDownloadLinkQuery,
  useGetTransferFilesQuery,
  useGetTransferQuery,
  useLazyGetDownloadLinkQuery,
  useGetTransfersByUserQuery,
  useGetReceivedTransfersByUserQuery,
  useGetTransferTotalsByUserQuery,
  useDeleteTransferMutation,
  useGetTransferTotalsQuery,
  useGetTransferRecipientsQuery,
  useValidateTransferPasswordMutation,
  useUpdateTransferMutation,
} = transferApi;

export default transferApi;

import React from "react";
import {
  Input as ChakraInput,
  FormLabel,
  InputProps as ChakraInputsProps,
} from "@chakra-ui/react";

export interface InputProps extends ChakraInputsProps {
  label?: string;
}

const Input = ({ ...props }: InputProps) => {
  return (
    <>
      {props.label && <FormLabel htmlFor={props.id}>{props.label}</FormLabel>}

      <ChakraInput
        _placeholder={{ color: "white" }}
        focusBorderColor="brand.300"
        {...props}
      />
    </>
  );
};

export default Input;

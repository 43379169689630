import { ContainerProps } from "@chakra-ui/react";
import React from "react";
import Container from "./Container";

const FullscreenContainer = ({ ...rest }: ContainerProps) => {
  return (
    <Container
      w="100svw"
      maxW="unset"
      h="100svh"
      p="0"
      overflow="hidden"
      {...rest}
    >
      {rest.children}
    </Container>
  );
};

export default FullscreenContainer;

import React from "react";
import { HTMLMotionProps, motion } from "framer-motion";
import { Box, BoxProps } from "@chakra-ui/react";

const FadeTransition = ({ ...props }: HTMLMotionProps<"div"> & BoxProps) => {
  return (
    <Box
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          type: "tween",
          duration: 0.25,
          ease: "easeInOut",
        },
      }}
      exit={{
        opacity: 0,
      }}
      {...props}
    >
      {props.children}
    </Box>
  );
};

export default FadeTransition;

import React from "react";

import { Menu } from "../../common/Menu/Menu";
import Icon from "../../common/Icon/Icon";
import Link from "../../common/Link/Link";

const GeneralMenu = () => {
  return (
    <Menu isLazy>
      <Menu.Button>
        <Icon icon="bars" color="white" size="xl" />
      </Menu.Button>

      <Menu.List color="blackAlpha.700" zIndex="dropdown">
        <Link to="/">
          <Menu.Item>Send files</Menu.Item>
        </Link>

        <Link to="https://www.mailbigfile.com/pro" target="_blank">
          <Menu.Item>Pro</Menu.Item>
        </Link>

        <Link href="https://www.mailbigfile.com/business" target="_blank">
          <Menu.Item>Business</Menu.Item>
        </Link>

        <Link href="https://www.mailbigfile.com/about" target="_blank">
          <Menu.Item>About</Menu.Item>
        </Link>

        <Link href="https://www.mailbigfile.com/policies" target="_blank">
          <Menu.Item>Policies</Menu.Item>
        </Link>

        <Link href="https://www.mailbigfile.com/help" target="_blank">
          <Menu.Item>Help</Menu.Item>
        </Link>
      </Menu.List>
    </Menu>
  );
};

export default GeneralMenu;

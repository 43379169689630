import api, { QueryStringParameters, Timestamps } from "../api";
import { User } from "./user";

export interface Feedback {
  message: string;
  time: Timestamps;
  email?: string;
  contact?: boolean;
  user?: User;
}

const feedbackApi = api.injectEndpoints({
  endpoints: (build) => ({
    createFeedback: build.mutation({
      query: ({
        body,
        params,
      }: {
        body: Omit<Feedback, "user" | "time">;
        params?: QueryStringParameters;
      }) => ({
        url: `feedback`,
        method: "POST",
        body,
        params,
      }),
      transformResponse(baseQueryReturnValue: Feedback) {
        return baseQueryReturnValue;
      },
    }),
  }),
});

export const { useCreateFeedbackMutation } = feedbackApi;

export default feedbackApi;

import React from "react";
import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";

export interface FormControlProps extends ChakraFormControlProps {
  children: React.ReactNode;
  helper?: string | React.ReactNode;
  error?: string | React.ReactNode;
}

const FormControl = ({
  helper,
  error,
  children,
  ...props
}: FormControlProps) => {
  return (
    <ChakraFormControl {...props}>
      {children}

      {!props.isInvalid && helper && <FormHelperText>{helper}</FormHelperText>}

      {props?.isInvalid && error && (
        <FormErrorMessage color="white">{error}</FormErrorMessage>
      )}
    </ChakraFormControl>
  );
};

export default FormControl;

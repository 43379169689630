import { AwsS3Part } from "@uppy/aws-s3-multipart";
import api from "../api";

const uppyApi = api.injectEndpoints({
  endpoints: (build) => ({
    signPart: build.query({
      query: ({
        uploadId,
        partNumber,
        params,
      }: {
        uploadId: string;
        partNumber: number;
        params: { key: string };
      }) => ({
        url: `uppy/${uploadId}/${partNumber}`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: { url: string }) {
        return baseQueryReturnValue;
      },
    }),
    listParts: build.query({
      query: ({
        uploadId,
        params,
      }: {
        uploadId: string;
        params: { key: string };
      }) => ({
        url: `uppy/${uploadId}`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<AwsS3Part>) {
        return baseQueryReturnValue;
      },
    }),
    createMultipartUpload: build.mutation({
      query: ({
        body,
      }: {
        body: {
          filename: string;
          type?: string;
          size: number;
          meta: Record<string, unknown>;
        };
      }) => ({
        url: `uppy`,
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue: {
        uploadId: string;
        key: string;
      }) {
        return baseQueryReturnValue;
      },
    }),
    completeMultipartUpload: build.mutation({
      query: ({
        uploadId,
        body,
      }: {
        uploadId: string;
        body: { Key: string; Parts: Array<AwsS3Part> };
      }) => ({
        url: `uppy/${uploadId}`,
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue: { location: string }) {
        return baseQueryReturnValue;
      },
    }),
    abortMultipartUpload: build.mutation({
      query: ({
        uploadId,
        body,
      }: {
        uploadId: string;
        body: { Key: string };
      }) => ({
        url: `uppy/${uploadId}`,
        method: "DELETE",
        body,
      }),
    }),
  }),
});

export const {
  useCreateMultipartUploadMutation,
  useAbortMultipartUploadMutation,
  useCompleteMultipartUploadMutation,
  useListPartsQuery,
  useSignPartQuery,
} = uppyApi;

export default uppyApi;

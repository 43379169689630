import React from "react";
import {
  Menu as ChakraMenu,
  MenuButton,
  MenuButtonProps,
  MenuDivider,
  MenuDividerProps,
  MenuGroup,
  MenuGroupProps,
  MenuItem,
  MenuItemOption,
  MenuItemOptionProps,
  MenuItemProps,
  MenuList,
  MenuListProps,
  MenuOptionGroup,
  MenuOptionGroupProps,
  MenuProps,
} from "@chakra-ui/react";

const Component = ({ ...props }: MenuProps) => {
  return <ChakraMenu>{props.children}</ChakraMenu>;
};

const Button = ({ ...props }: MenuButtonProps) => {
  return <MenuButton {...props}>{props.children}</MenuButton>;
};

const List = ({ ...props }: MenuListProps) => {
  return <MenuList {...props}>{props.children}</MenuList>;
};

const Item = ({ ...props }: MenuItemProps) => {
  return <MenuItem {...props}>{props.children}</MenuItem>;
};

const ItemOption = ({ ...props }: MenuItemOptionProps) => {
  return <MenuItemOption {...props}>{props.children}</MenuItemOption>;
};

const Group = ({ ...props }: MenuGroupProps) => {
  return <MenuGroup {...props}>{props.children}</MenuGroup>;
};

const OptionGroup = ({ ...props }: MenuOptionGroupProps) => {
  return <MenuOptionGroup {...props}>{props.children}</MenuOptionGroup>;
};

const Divider = ({ ...props }: MenuDividerProps) => {
  return <MenuDivider {...props}>{props.children}</MenuDivider>;
};

Component.displayName = "Menu";
Button.displayName = "Menu.Button";
List.displayName = "Menu.List";
Item.displayName = "Menu.Item";
ItemOption.displayName = "Menu.ItemOption";
Group.displayName = "Menu.Group";
OptionGroup.displayName = "Menu.OptionGroup";
Divider.displayName = "Menu.Divider";

export const Menu = Object.assign(Component, {
  Button,
  List,
  Item,
  ItemOption,
  Group,
  OptionGroup,
  Divider,
});

import React from "react";
import {
  AccordionProps,
  Accordion as ChakraAccordion,
  AccordionButton as ChakraAccordionButton,
  AccordionIcon as ChakranAccordionIcon,
  AccordionItem as ChakraAccordionItem,
  AccordionPanel as ChakraAccordionPanel,
  AccordionButtonProps,
  AccordionIconProps,
  AccordionItemProps,
  AccordionPanelProps,
} from "@chakra-ui/react";

const Component = ({ ...props }: AccordionProps) => {
  return <ChakraAccordion {...props}>{props.children}</ChakraAccordion>;
};

const Button = ({ ...props }: AccordionButtonProps) => {
  return (
    <ChakraAccordionButton {...props}>{props.children}</ChakraAccordionButton>
  );
};

const Icon = ({ ...props }: AccordionIconProps) => {
  return (
    <ChakranAccordionIcon {...props}>{props.children}</ChakranAccordionIcon>
  );
};

const Item = ({ ...props }: AccordionItemProps) => {
  return <ChakraAccordionItem {...props}>{props.children}</ChakraAccordionItem>;
};

const Panel = ({ ...props }: AccordionPanelProps) => {
  return (
    <ChakraAccordionPanel {...props}>{props.children}</ChakraAccordionPanel>
  );
};

Component.displayName = "Modal";
Button.displayName = "Modal.Button";
Icon.displayName = "Modal.Icon";
Item.displayName = "Modal.Item";
Panel.displayName = "Modal.Panel";

export default Object.assign(Component, { Button, Icon, Item, Panel });

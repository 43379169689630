import api from "../api";

export interface Continent {
  code: string;
  genome_id: number;
  names: Record<string, string>;
}

export interface Country {
  code: string;
  iso_code: string;
  names: Record<string, string>;
}

export interface Region {
  continent: Continent;
  country: Country;
  currency: string
}

export interface Client {
  ip?: string;
  region?: Region;
}

const fileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRegion: build.query({
      query: () => ({
        url: `client/region`,
        method: "GET",
      }),
      transformResponse(baseQueryReturnValue: Region) {
        return baseQueryReturnValue;
      },
      providesTags: (result) => {
        if (!result) {
          return ["Client"];
        }

        return [{ type: "Client", id: result.continent.code }];
      },
    }),
  }),
});

export const { useGetRegionQuery } = fileApi;

export default fileApi;

import { Box, BoxProps, Text } from "@chakra-ui/react";
import React from "react";
import Link from "../common/Link/Link";

const UploadDisclaimer = ({ ...props }: BoxProps) => {
  return (
    <Box
      display="flex"
      flexDir="column"
      gap="2"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      p="4"
      {...props}
    >
      <Text fontSize="sm">
        This site is protected by reCAPTCHA. The Google{" "}
        <Link
          href="https://policies.google.com/privacy"
          target="_blank"
          textDecor="underline"
        >
          Privacy Policy
        </Link>{" "}
        and&nbsp;
        <Link
          href="https://policies.google.com/terms"
          target="_blank"
          textDecor="underline"
        >
          Terms of Service
        </Link>{" "}
        apply.
      </Text>

      <Text fontSize="xs">MailBigFile&reg; is a registered trademark</Text>

      <Text fontSize="xs">
        &copy;2005-{new Date().getFullYear()}. By Dessol.
      </Text>
    </Box>
  );
};

export default UploadDisclaimer;

import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          favicon
          siteUrl
        }
      }
    }
  `);

  return data.site.siteMetadata;
};

export const SEO = ({
  title,
  description,
  pathname,
  children,
}: {
  title?: string;
  description?: string;
  pathname?: string;
  children?: React.ReactNode;
}) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    favicon,
    siteUrl,
  } = useSiteMetadata();

  const seo = {
    title: title || `${defaultTitle} | ${defaultDescription}`,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
    favicon: `${favicon}`,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      {/* <meta name="image" content={seo.image} /> */}
      {/* <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} /> */}
      <link rel="icon" href={seo.favicon} type="image/png" />
      {children}
    </>
  );
};

import api from "../api";

const loginApi = api.injectEndpoints({
  endpoints: (build) => ({
    createLogin: build.mutation({
      query: () => ({
        url: `login`,
        method: "POST",
      }),
    }),
  }),
});

export const { useCreateLoginMutation } = loginApi;

export default loginApi;

class BrowserNotification {
  get supported() {
    return "Notification" in window;
  }

  notify({
    title,
    options,
  }: {
    title: string;
    options?: NotificationOptions & {
      closeOnVisible?: boolean;
      showAppOnClick?: boolean;
    };
  }) {
    if (!this.supported) {
      throw new Error(
        "Desktop notifications are not supported by this browser or device",
      );
    }

    const notification = new Notification(title, {
      icon: "../../images/logoSmall.png",
      badge: "../../images/logoSmall.png",
      ...options,
    });

    if (options?.closeOnVisible) {
      const event = () => {
        if (document.visibilityState === "visible") {
          notification.close();

          document.removeEventListener("visibilitychange", event);
        }
      };

      document.addEventListener("visibilitychange", event);
    }

    if (options?.showAppOnClick) {
      notification.addEventListener("click", (ev) => {
        window.focus();
      });
    }
  }
}

export default new BrowserNotification();

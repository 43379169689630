import React from "react";
import {
  Modal as ChakraModal,
  ModalOverlay as ChakraModalOverlay,
  ModalContent as ChakraModalContent,
  ModalHeader as ChakraModalHeader,
  ModalFooter as ChakraModalFooter,
  ModalBody as ChakraModalBody,
  ModalCloseButton as ChakraModalCloseButton,
  ModalProps,
  ModalOverlayProps,
  ModalContentProps,
  ModalHeaderProps,
  ModalFooterProps,
  ModalBodyProps,
  ModalCloseButtonProps,
} from "@chakra-ui/react";

const Component = ({ ...props }: ModalProps) => {
  return <ChakraModal {...props}>{props.children}</ChakraModal>;
};

const Overlay = ({ ...props }: ModalOverlayProps) => {
  return <ChakraModalOverlay {...props}>{props.children}</ChakraModalOverlay>;
};

const Content = ({ ...props }: ModalContentProps) => {
  return <ChakraModalContent {...props}>{props.children}</ChakraModalContent>;
};

const Header = ({ ...props }: ModalHeaderProps) => {
  return <ChakraModalHeader {...props}>{props.children}</ChakraModalHeader>;
};

const Footer = ({ ...props }: ModalFooterProps) => {
  return <ChakraModalFooter {...props}>{props.children}</ChakraModalFooter>;
};

const Body = ({ ...props }: ModalBodyProps) => {
  return <ChakraModalBody {...props}>{props.children}</ChakraModalBody>;
};

const CloseButton = ({ ...props }: ModalCloseButtonProps) => {
  return (
    <ChakraModalCloseButton {...props}>{props.children}</ChakraModalCloseButton>
  );
};

Component.displayName = "Modal";
Overlay.displayName = "Modal.Overlay";
Content.displayName = "Modal.Content";
Header.displayName = "Modal.Header";
Footer.displayName = "Modal.Footer";
Body.displayName = "Modal.Body";
CloseButton.displayName = "Modal.CloseButton";

export const Modal = Object.assign(Component, {
  Overlay,
  Content,
  Header,
  Footer,
  Body,
  CloseButton,
});
export default Modal;

import React from "react";
import {
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  FormLabel,
} from "@chakra-ui/react";

export interface TextareaProps extends ChakraTextareaProps {
  label?: string;
}

const Textarea = ({ ...props }: TextareaProps) => {
  return (
    <>
      <ChakraTextarea
        _placeholder={{ color: "white" }}
        focusBorderColor="brand.300"
        {...props}
      />

      {props.label && <FormLabel htmlFor={props.id}>{props.label}</FormLabel>}
    </>
  );
};

export default Textarea;

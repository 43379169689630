import { format } from "date-fns";

export enum LogLevel {
  Debug = 0,
  Info = 1,
  Warn = 2,
  Error = 3,
}

class Logger {
  public logLevel: LogLevel = 0;

  debug(message?: any, ...optionalParams: any[]) {
    if (this.logLevel <= LogLevel.Debug) {
      console.debug(
        "[MailBigFile]",
        `[${format(new Date(), "HH:mm:ss")}]`,
        message,
        ...optionalParams,
      );
    }
  }

  info(message?: any, ...optionalParams: any[]) {
    if (this.logLevel <= LogLevel.Info) {
      console.log(
        "[MailBigFile]",
        `[${format(new Date(), "HH:mm:ss")}]`,
        message,
        ...optionalParams,
      );
    }
  }

  warn(message?: any, ...optionalParams: any[]) {
    if (this.logLevel <= LogLevel.Warn) {
      console.warn(
        "[MailBigFile]",
        `[${format(new Date(), "HH:mm:ss")}]`,
        message,
        ...optionalParams,
      );
    }
  }

  error(message?: any, ...optionalParams: any[]) {
    if (this.logLevel <= LogLevel.Error) {
      console.error(
        "[MailBigFile]",
        `[${format(new Date(), "HH:mm:ss")}]`,
        message,
        ...optionalParams,
      );
    }
  }
}

export default new Logger();

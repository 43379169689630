import React from "react";
import FullscreenContainer from "./Container/FullScreen";
import { StaticImage } from "gatsby-plugin-image";
import Loading from "./Loading";
import { ContainerProps } from "@chakra-ui/react";

export const SplashScreen = ({
  text = "Send Large Files Quickly and Easily",
  ...props
}: { text?: string } & ContainerProps) => {
  return (
    <FullscreenContainer
      id="splash-screen"
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      gap="1rem"
      bgGradient="linear(to-tr, #625fa2, #e358b4)"
      {...props}
    >
      <StaticImage
        src="../../images/logoSmall.png"
        alt="MailBigFile Logo"
        height={64}
      />

      <Loading spinner={false} color="white" text={text} />
    </FullscreenContainer>
  );
};

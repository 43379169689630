// FontAwesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCcAmazonPay,
  faCcAmex,
  faCcApplePay,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcPaypal,
  faCcVisa,
  faGooglePay,
  faPaypal,
} from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faUser,
  faCog,
  faTimesCircle,
  faSpinner,
  faLink,
  faDownload,
  faClipboard,
  faLock,
  faArrowCircleRight,
  faExternalLink,
  faImage,
  faArrowRight,
  faArrowLeft,
  faFile,
  faFileArrowDown,
  faFiles,
  faComment,
  faMagnifyingGlass,
  faQuestionCircle,
  faChevronCircleDown,
  faTrash,
  faExclamationCircle,
  faUpload,
  faInfoCircle,
  faInboxOut,
  faInboxIn,
  faEnvelope,
  faTick,
  faCheck,
  faLevelUp,
  faCircleUp,
  faClock,
  faEye,
  faEyeSlash,
  faKey,
  faTimes,
  faPaperPlane,
  faBuildingColumns,
} from "@fortawesome/pro-solid-svg-icons";

const FontAwesomePreloader = ({ ...props }) => {
  library.add(
    faBars,
    faUser,
    faCog,
    faTimesCircle,
    faSpinner,
    faLink,
    faDownload,
    faClipboard,
    faLock,
    faArrowCircleRight,
    faExternalLink,
    faImage,
    faArrowRight,
    faArrowLeft,
    faFile,
    faFiles,
    faComment,
    faFileArrowDown,
    faMagnifyingGlass,
    faQuestionCircle,
    faChevronCircleDown,
    faTrash,
    faExclamationCircle,
    faUpload,
    faInfoCircle,
    faInboxOut,
    faInboxIn,
    faEnvelope,
    faTick,
    faCheck,
    faLevelUp,
    faCircleUp,
    faClock,
    faEye,
    faEyeSlash,
    faKey,
    faInfoCircle,
    faTimes,
    faPaperPlane,
    faDownload,
    faBuildingColumns,
    // @ts-ignore
    faPaypal,
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCcAmazonPay,
    faCcApplePay,
    faCcPaypal,
    faCcDiscover,
    faCcDinersClub,
    faCcJcb,
    faGooglePay,
  );

  return props.children;
};

export default FontAwesomePreloader;
